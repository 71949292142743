import {INIT_STICKIES} from "../js/init.stickies";
import {INIT_FANCYBOXES} from "../js/init.fancyboxes";

document.addEventListener('DOMContentLoaded',  () => {


const ROOT_ELEMENT = document.querySelector('[data-stickies-row]');
if (ROOT_ELEMENT) INIT_STICKIES(ROOT_ELEMENT);

INIT_FANCYBOXES();
})
